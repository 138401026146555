body {
  margin: 0;
  font-family: "Source Sans Pro", "Roboto", "Oxygen", "Ubuntu", "Cantarell",
    "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif, -apple-system,
    BlinkMacSystemFont, "Segoe UI";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f7f8fa !important;
  position: relative !important;
  height: auto !important;
  /* overflow-y: scroll !important; */
}

html {
  overflow: scroll;
  overflow-x: hidden;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

*::-webkit-scrollbar {
  width: 7px;
  height: 7px;
  transition: background-color 0.5s ease;

  /* width of the entire scrollbar */
}

*::-webkit-scrollbar-track {
  background: #e6e1f100;

  /* color of the tracking area */
}

*::-webkit-scrollbar-thumb {
  background-color: #958ca0;
  /* color of the scroll thumb */
  border-radius: 20px;
  /* roundness of the scroll thumb */
  border: 1px solid #e6e1f1;
  /* creates padding around scroll thumb */
}
