#versionIndicator {
  position: fixed;
  bottom: 5px;
  right: 10px;
  font-size: 12px;
  color: #2e97c8;
}

.CircularProgressbar {
  width: 50px !important;
}

.CircularProgressbar .CircularProgressbar-text {
  font-size: 25px !important;
  font-weight: 700 !important;
}

dt {
  width: 30%;
  display: inline-block;
  color: black;
  font-weight: 400;
  padding-bottom: 2%;
  vertical-align: top;
}

dd {
  width: 60%;
  display: inline-block;
  font-weight: 400;
  padding-bottom: 2%;
}

/* check mark success */

.circle-loader {
  margin-bottom: 1.5em;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-left-color: #2e97c8;
  animation: loader-spin 1.2s infinite linear;
  position: relative;
  display: inline-block;
  vertical-align: top;
  border-radius: 50%;
  width: 5em;
  height: 5em;
}

.load-complete {
  -webkit-animation: none;
  animation: none;
  border-color: #fff;
  background-color: #2e97c8;
  transition: border 500ms ease-out;
}

.checkmark {
  display: none;
}

.checkmark.draw:after {
  animation-duration: 800ms;
  animation-timing-function: ease;
  animation-name: checkmark;
  transform: scaleX(-1) rotate(135deg);
}

.checkmark:after {
  opacity: 1;
  height: 1.8em;
  width: 0.75em;
  transform-origin: left top;
  border-right: 3px solid #fff;
  border-top: 3px solid #fff;
  content: "";
  left: 1.5em;
  top: 2.5em;
  position: absolute;
}

@keyframes loader-spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes checkmark {
  0% {
    height: 0;
    width: 0;
    opacity: 1;
  }
  20% {
    height: 0;
    width: 0.65em;
    opacity: 1;
  }
  40% {
    height: 1.5em;
    width: 0.75em;
    opacity: 1;
  }
  100% {
    height: 1.8em;
    width: 0.75em;
    opacity: 1;
  }
}

div.loading {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(16, 16, 16, 0.15);
  display: flex;
  align-items: center;
  justify-content: center;
}
div.loading .info {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background: #ffffff;
  border-radius: 10px;
  padding: 50px;
}

.dot {
  height: 12px;
  width: 12px;
  border-radius: 50%;
  display: inline-block;
  background: #d5d5d5;
}
.dot.positive {
  background: #4fc605;
}
.dot.negative {
  background: #df0011;
}

.button-positive {
  color: #4fc605 !important;
  border-color: #4fc605 !important;
}
.button-warning {
  color: #ffb300 !important;
  border-color: #ffb300 !important;
}
.button-negative {
  color: #df0011 !important;
  border-color: #df0011 !important;
}
.center {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}
.center.space_around {
  justify-content: space-around;
}
.arrow {
  position: relative;
  color: #fff;
  font-weight: bold;
  margin-bottom: 5px;
}
#arrow4:after {
  content: "";
  height: 0;
  display: block;
  border-color: transparent transparent transparent #2e97c8;
  border-width: 20px;
  border-style: solid;
  position: absolute;
  top: -10px;
  left: 151px;
}
.red {
  height: 50px;
  padding: 10px;
  width: 160px;
  color: #fff;
  background: #2e97c8;
}
.red1 {
  height: 50px;
  padding: 10px;
  width: 160px;
  color: #fff;
  background: #2e97c8;
}
/* trial */

.wizard a:after {
  width: 0;
  height: 0;
  border-top: 20px inset transparent;
  border-bottom: 20px inset transparent;
  border-left: 21px solid #efefef;
  position: absolute;
  content: “”;
  top: 0;
  right: -20px;
  z-index: 2;
}
.wizard a:first-child:before,
.wizard a:last-child:after {
  border: none;
}
.align-left-space-between {
  display: flex;
  text-align: left;
  align-items: flex-start;
  justify-content: space-between;
}

.vertical-dots {
  height: 90%;
  position: absolute;
  z-index: -1;
  top: 0;
  bottom: 0;
  left: 10%;
  margin: 3rem 0;
  border-left: 1px dashed #70707073;
}

@media only screen and (max-width: 768px) {
  /* For mobile phones: */
  dt,
  dd {
    font-size: 12px;
  }

  dd {
    width: 55%;
  }
}
