body {
  /* padding: 2em; */
}
.steps {
  list-style-type: none;
  padding: 0;
}
.steps li {
  display: inline-block;
  margin-bottom: 3px;
}
.steps li span,
.steps li p {
  background: #e5f4fd;
  padding: 8px 20px;
  color: #0077bf;
  display: block;
  font-size: 14px;
  font-weight: bold;
  position: relative;
  text-indent: 12px;
}
.steps li span:hover,
.steps li p:hover {
  text-decoration: none;
}
.steps li span:before {
  border-bottom: 18px solid transparent;
  border-top: 18px solid transparent;
  content: "";
  height: 0;
  position: absolute;
  left: 0;
  top: 50%;
  width: 0;
  margin-top: -18px;
}
.steps li p:before {
  border-bottom: 18px solid transparent;
  border-left: 12px solid #fff;
  border-top: 18px solid transparent;
  content: "";
  height: 0;
  position: absolute;
  left: 0;
  top: 50%;
  width: 0;
  margin-top: -18px;
}
.steps li span:after,
.steps li p:after {
  border-bottom: 18px solid transparent;
  border-left: 12px solid #e5f4fd;
  border-top: 18px solid transparent;
  content: "";
  height: 0;
  position: absolute;
  /*right: -12px;*/
  left: 100%;
  top: 50%;
  width: 0;
  margin-top: -18px;
  z-index: 1;
}
.steps li.active span,
.steps li.active p {
  background: #00bbff;
  color: #fff;
}
.steps li.active span:after,
.steps li.active p:after {
  border-left: 12px solid #00bbff;
}
.steps li.undone span,
.steps li.undone p {
  background: #eee;
  color: #333;
}
.steps li.undone span:after,
.steps li.undone p:after {
  border-left: 12px solid #eee;
}
.steps li.undone p {
  color: #aaa;
}
